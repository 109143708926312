import {
    differenceInDays,
    format,
    getDay,
    sub as subDuration,
} from 'date-fns';

import { Period as PeriodEnum } from '~/app/shared/enums/period.enum';
import { Period } from '~/app/shared/types/period.type';

export function getDateOfTheDayIso(date: string | number, hours: number = 0, minutes: number = 0, seconds: number = 0): string {
    const endDate = new Date(date);
    return new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), hours, minutes, seconds, 0)).toISOString();
}

export function getDate(date: string): string {
    return format(new Date(date), 'yyyy-MM-dd');
}

export function getBusinessDate(date: Date): string {
    let businessDate = date;
    let day = getDay(businessDate);
    while (day === 0 || day === 6) {
        businessDate = subDuration(businessDate, { days: 1 });
        day = getDay(businessDate);
    }
    return businessDate.toISOString();
}

export function getPeriodsDisabledFromDate(periods: Period[], fromDate: string, limitDate: string): string[] {
    const periodsDisabled: string[] = [];
    const fromDateConverted = new Date(fromDate);
    const limitDateConverted = new Date(limitDate);

    periods.forEach((period) => {
        // eslint-disable-next-line default-case
        switch (period.code) {
            case PeriodEnum.P09W:
                if (differenceInDays(subDuration(fromDateConverted, { weeks: 9 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P01D:
                if (differenceInDays(subDuration(fromDateConverted, { days: 1 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P07D:
                if (differenceInDays(subDuration(fromDateConverted, { days: 7 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P01M:
                if (differenceInDays(subDuration(fromDateConverted, { months: 1 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P02M:
                if (differenceInDays(subDuration(fromDateConverted, { months: 2 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P03M:
                if (differenceInDays(subDuration(fromDateConverted, { months: 3 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P06M:
                if (differenceInDays(subDuration(fromDateConverted, { months: 6 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P09M:
                if (differenceInDays(subDuration(fromDateConverted, { months: 9 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P01Y:
                if (differenceInDays(subDuration(fromDateConverted, { years: 1 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P03Y:
                if (differenceInDays(subDuration(fromDateConverted, { years: 3 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P05Y:
                if (differenceInDays(subDuration(fromDateConverted, { years: 5 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
            case PeriodEnum.P10Y:
                if (differenceInDays(subDuration(fromDateConverted, { years: 10 }), limitDateConverted) < 0) {
                    periodsDisabled.push(period.code);
                }
                break;
        }
    });

    return periodsDisabled;
}
